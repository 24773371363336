import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
  background: ${(props) => (props.theme === 'dark' ? '#333' : '#eee')};
  color: ${(props) => (props.theme === 'dark' ? '#FFD700' : '#B22222')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SiteName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
    text-align: center;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  color: inherit;
  font-size: 1rem;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

const Header = ({ theme, toggleTheme }) => {
  return (
    <Nav theme={theme}>
      <SiteName>KaliMeNope.de</SiteName>
      <Links>
        <Link to="/">Home</Link>
        <Link to="/projects">Projects</Link>
        <Link to="/contact">Contact</Link>
        <a href="https://github.com/GitByKaliMeNope/" target="_blank" rel="noopener noreferrer">GitHub</a>
        <a href="https://www.linkedin.com/in/timo-meng-90aba027a/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      </Links>
      <Button onClick={toggleTheme}>Toggle Theme</Button>
    </Nav>
  );
};

export default Header;
