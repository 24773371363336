import React, { useState, useEffect } from "react";
import styled from "styled-components";


const Clock = styled.div`
 display :flex;
  text-align: center;
  padding: 1rem;
  justify-content: center;

`;
const span = styled.span`
 background: ${(props) => (props.theme === 'dark' ? '#333' : '#eee')};
  color: ${(props) => (props.theme === 'dark' ? '#FFD700' : '#B22222')};
 
`;

const ClockTime = (props) => {
  const [dateTime, setDateTime] = useState(new Date().toUTCString());

  useEffect(() => {
    document.title = 'KaliMeNope.de';
    const clockInter = setInterval(() => {
      setDateTime(new Date().toUTCString());
    }, 1000);

    return () => clearInterval(clockInter);
  }, []);

  return (
    <Clock theme={props.theme}>
      <span id='Uhrzeit' className="clock"> {dateTime}
      </span>
    </Clock>
  );
};

export default ClockTime;
