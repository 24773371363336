import React from 'react';
import Game from './Game';
import './Super-Mario.css';

function Smario() {
  return (
    <div className="App">
      <Game />
    </div>
  );
}

export default Smario;