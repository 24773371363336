import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
`;


const ProjectCard = ({ title, description, link, element }) => {
  return (
    <CardContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
     
      {element}
    </CardContainer>
  );
};

export default ProjectCard;
