import React from 'react';
import styled from 'styled-components';
import ProjectCard from '../components/ProjectCard'; // Hier importieren wir ProjectCard
import Smario from './mario/Super-Mario';
import VierGewinnt from './4gewinnt';

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
    hight: 100%;
   
   width: 80%;
  
  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: irem;
  
  }
`;

const Projects = () => {
  return (
    <ProjectsContainer>
      <ProjectCard
        title="4 Gewinnt"
        description="Ein einfaches 4-Gewinnt-Spiel in React."
        element={<VierGewinnt />} 
      />
      <ProjectCard
        title="Super-Mario"
        description="Ein Anfang einer Super-Mario Engine"
        element={<Smario />} 
      />
    
    </ProjectsContainer>
  );
};

export default Projects;
