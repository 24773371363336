// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import ClockTime from './Clock';


const Foot = styled.footer`
  background: ${(props) => (props.theme === 'dark' ? '#333' : '#eee')};
  color: ${(props) => (props.theme === 'dark' ? '#FFD700' : '#B22222')};
  text-align: center;
  padding: 1rem;
  width: 100%;
  bottom: 0;
  position: relative;
  transition: all 0.3s ease;
`;



const Footer = ({ theme }) => {
  return <Foot theme={theme}>&copy; 2024 KaliMeNope: Mein Portfolio  
    <ClockTime theme="dark" />
  </Foot>;
};

export default Footer;
