import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${(props) => (props.theme === 'dark' ? '#000' : '#fff')};
    color: ${(props) => (props.theme === 'dark' ? '#FFD700' : '#B22222')};
    font-family: ${(props) => (props.theme === 'dark' ? 'Arial, sans-serif' : 'Courier New, Courier, monospace')};
    transition: all 0.3s ease;
  }

  a {
    color: ${(props) => (props.theme === 'dark' ? '#FFD700' : '#B22222')};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export default GlobalStyles;